import { SecureRoute, Security, LoginCallback } from "@okta/okta-react";
import React, { useMemo } from "react";
import { Route, useHistory, Switch } from "react-router-dom";

import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
// import Okta from './Okta';
import Okta from "./Okta";
import config from "./config";

const AppRouter = () => {
  const history = useHistory();

  const oktaAuth = useMemo(() => new OktaAuth(config.oidc), []);

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <SecureRoute path="/" exact component={Okta} />
        <Route path="/login/callback" component={LoginCallback} />
      </Switch>
    </Security>
  );
};
export default AppRouter;
