import axios from "axios";
import { oktaToken } from "../utils/constants";

const request = async ({ method, headers, url, data }) => {
  return await axios.request({
    method,
    headers,
    url: `/api/${url}`,
    data,
    withCredentials: true,
    crossDomain: true,
  });
};

const { accessToken: token, idToken } =
  JSON.parse(localStorage.getItem(oktaToken)) || {};
const initiatedBy = idToken?.claims?.email;

export const getAllTenants = async () => {
  return request({
    method: "get",
    headers: {
      Authorization: `${token?.tokenType} ${token?.accessToken}`,
    },
    url: `tenants`,
  });
};

export const getPhonesByTenantId = async ({ tenantId, data }) => {
  return request({
    method: "post",
    headers: {
      Authorization: `${token?.tokenType} ${token?.accessToken}`,
    },
    url: `tenant/${tenantId}/phones`,
    data,
  });
};

export const addPhone = async (data) => {
  return request({
    method: "post",
    headers: {
      Authorization: `${token?.tokenType} ${token?.accessToken}`,
    },
    data: { ...data, ...(initiatedBy && { initiatedBy }) },
    url: `phone`,
  });
};

export const updatePhone = async ({ phoneId, data }) => {
  return request({
    method: "patch",
    headers: {
      Authorization: `${token?.tokenType} ${token?.accessToken}`,
    },
    data: { ...data, ...(initiatedBy && { initiatedBy }) },
    url: `phone/${phoneId}`,
  });
};

export const updatePhoneEnv = async ({ phoneId, data }) => {
  return request({
    method: "post",
    headers: {
      Authorization: `${token?.tokenType} ${token?.accessToken}`,
    },
    data: { ...data, ...(initiatedBy && { initiatedBy }) },
    url: `phone/${phoneId}/environment`,
  });
};

export const deletePhoneEnv = async ({ phoneId, entityEnvId }) => {
  return request({
    method: "delete",
    headers: {
      Authorization: `${token?.tokenType} ${token?.accessToken}`,
    },
    url: `phone/${phoneId}/environment/${entityEnvId}?initiated_by=${initiatedBy}`,
  });
};

export const deletePhoneById = async (phoneId) => {
  return request({
    method: "delete",
    headers: {
      Authorization: `${token?.tokenType} ${token?.accessToken}`,
    },
    url: `phone/${phoneId}?initiated_by=${initiatedBy}`,
  });
};

export const rebuildPhoneById = async (phoneId) => {
  return request({
    method: "post",
    headers: {
      Authorization: `${token?.tokenType} ${token?.accessToken}`,
    },
    url: `phone/${phoneId}/rebuild`,
    data: { ...(initiatedBy && { initiatedBy }) },
  });
};
