import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useDispatch, useSelector } from "react-redux";

import Loading from "./components/common/Loading";
import Home from "./Home";

import {
  updateUserInfo,
  getUserInfo,
  updateUserRoles,
} from "./features/okta/oktaSlice";

const Okta = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector(getUserInfo);
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      dispatch(updateUserInfo(null));
    } else {
      oktaAuth.getUser().then((info) => {
        dispatch(updateUserInfo(info));
      });
      if (authState?.accessToken?.claims?.roles)
        dispatch(updateUserRoles(authState?.accessToken?.claims?.roles));
    }
  }, [authState, oktaAuth, dispatch]);

  if (!authState || !userInfo) return <Loading />;
  else return <Home />;
};

export default Okta;
