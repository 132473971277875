import { createTheme, alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const primaryColor = "#0020C6";
const white = "#fff";
const black = "#000";

export const PhonismTheme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
      contrastText: white,
    },
    info: {
      main: primaryColor,
    },
    tonalOffset: 0.2,
    contrastThreshold: 3,
  },
  components: {
    MuiCircularProgress: {
      color: primaryColor,
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: black,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          width: "auto !important",
        },
      },
    },
  },
});

export const useAddStyles = makeStyles((theme) => ({
  topContainer: {
    display: "flex",
    alignContent: "space-around",
    justifyContent: "space-between",
  },
  add: {
    margin: theme.spacing(2),
  },
  root: {
    color: "#000 !important",
    margin: `${theme.spacing(0.5)} !important`,
    width: `calc(100% - ${theme.spacing(2)}) !important`,
  },
  outlinedInput: {
    color: black,
  },
  disabled: {
    color: "rgba(0, 32, 198, 0.1)",
  },
  icon: {
    color: `${black} !important`,
    opacity: 0.54,
    "&:hover": {
      opacity: 1,
    },
  },
  button: {
    fontWeight: "900 !important",
    margin: theme.spacing(0.5),
    "&:hover": {
      backgroundColor: "rgba(0, 32, 198, 0.1)",
    },
  },
}));

export const useHeaderStyles = makeStyles((theme) => ({
  select: {
    color: `${white} !important`,
  },
  icon: {
    fill: `${alpha(white, 0.54)} !important`,
  },
}));

export const useTableStyles = makeStyles((theme) => ({
  tableRoot: {
    color: `${black} !important`,
    margin: theme.spacing(6),
  },
}));

export const useLoadingStyles = makeStyles(() => ({
  loading: {
    color: primaryColor,
  },
}));

export const useSearchStyles = makeStyles((theme) => ({
  search: {
    margin: theme.spacing(2),
    border: 1,
    borderStyle: "solid",
    borderRadius: theme.spacing(1),
    borderColor: black,
    opacity: 0.54,
    "&:hover": {
      opacity: 1,
    },
  },
}));

export const userStyles = {
  avatar: {
    width: 38,
    height: 38,
    color: primaryColor,
    backgroundColor: white,
  },
  menu: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      zIndex: 0,
    },
  },
};
