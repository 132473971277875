import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addPhone,
  rebuildPhoneById,
  deletePhoneById,
  getPhonesByTenantId,
  updatePhone,
  updatePhoneEnv,
  deletePhoneEnv,
} from "../../services";
import { STATUS, MESSAGES } from "../../utils/constants";

export const listPhonesByTenantId = createAsyncThunk(
  "phones/listPhonesByTenantId",
  async ({ tenantId, search, pagination }) => {
    const { data } = await getPhonesByTenantId({
      tenantId,
      data: { search, pagination },
    });
    return data;
  }
);

export const addNewPhone = createAsyncThunk(
  "phones/addNewPhone",
  async (
    { macAddress, tenandId, entityEnvs, phoneAlias },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await addPhone({
        macAddress,
        tenandId,
        entityEnvs,
        phoneAlias,
      });
      return data;
    } catch (err) {
      return err?.response?.data?.message
        ? rejectWithValue(err?.response?.data?.message)
        : rejectWithValue(
            `Something went wrong while adding phone with mac address: ${macAddress}`
          );
    }
  }
);

export const updatePhoneAlias = createAsyncThunk(
  "phones/updatePhoneAlias",
  async ({ phoneId, phoneAlias }, { rejectWithValue }) => {
    try {
      const { data } = await updatePhone({ phoneId, data: { phoneAlias } });
      return data;
    } catch (err) {
      return err?.response?.data?.message
        ? rejectWithValue(err?.response?.data?.message)
        : rejectWithValue(
            `Something went wrong while updating phone alias, phone id: ${phoneId}`
          );
    }
  }
);

export const updatePhoneEntityEnv = createAsyncThunk(
  "phones/updatePhoneEntityEnv",
  async ({ phoneId, entityEnvId }, { rejectWithValue }) => {
    try {
      const { data } = await updatePhoneEnv({
        phoneId,
        data: { entityEnvId },
      });
      return data;
    } catch (err) {
      return err?.response?.data?.message
        ? rejectWithValue(err?.response?.data?.message)
        : rejectWithValue(
            `Something went wrong while updating phone entity env. Phone id: ${phoneId}, Entity env: ${entityEnvId}`
          );
    }
  }
);

export const deletePhoneEntityEnv = createAsyncThunk(
  "phones/deletePhoneEntityEnv",
  async ({ phoneId, entityEnvId }, { rejectWithValue }) => {
    try {
      const { data } = await deletePhoneEnv({ phoneId, entityEnvId });
      return data;
    } catch (err) {
      return err?.response?.data?.message
        ? rejectWithValue(err?.response?.data?.message)
        : rejectWithValue(
            `Something went wrong while deleting phone entity env. Phone id: ${phoneId}, Entity env: ${entityEnvId}`
          );
    }
  }
);

export const rebuildPhone = createAsyncThunk(
  "phones/rebuildPhone",
  async (phoneId, { rejectWithValue }) => {
    try {
      const { data } = await rebuildPhoneById(phoneId);
      return data;
    } catch (err) {
      return err?.response?.data?.message
        ? rejectWithValue(err?.response?.data?.message)
        : rejectWithValue(
            `Something went wrong while rebuiling phone id: ${phoneId}`
          );
    }
  }
);

export const deletePhone = createAsyncThunk(
  "phones/deletePhone",
  async (phoneId, { rejectWithValue }) => {
    try {
      const { data } = await deletePhoneById(phoneId);
      return { data, phoneId };
    } catch (err) {
      return err?.response?.data?.message
        ? rejectWithValue(err?.response?.data?.message)
        : rejectWithValue(
            `Something went wrong while deleting phone id: ${phoneId}`
          );
    }
  }
);

export const phoneSlice = createSlice({
  name: "phoneState",
  initialState: {
    phones: [],
    listPhonesStatus: null,
    listPhonesFeedbackMessage: "",
    addPhoneStatus: null,
    addPhoneFeedbackMessage: "",
    updatePhoneStatus: null,
    updateFeedbackMessage: "",
    rebuildPhoneStatus: null,
    rebuildFeedbackMessage: "",
    deletePhoneStatus: null,
    deleteFeedbackMessage: "",
    newMacAddress: "",
    newAlias: "",
    searchTerm: "",
    page: 1,
    isLastPage: false,
    isOnlyPage: true,
    count: 1,
    cursors: {},
    pagination: {
      cursor: "",
      limit: 20,
    },
  },
  reducers: {
    updateMacAddress: (state, { payload }) => {
      state.newMacAddress = payload;
    },
    updateAlias: (state, { payload }) => {
      state.newAlias = payload;
    },
    updateSearch: (state, { payload }) => {
      state.searchTerm = payload;
    },
    updatePage: (state, { payload }) => {
      state.page = payload;
    },
  },
  extraReducers: {
    [listPhonesByTenantId.pending]: (state) => {
      state.listPhonesStatus = STATUS.PENDING;
    },
    [listPhonesByTenantId.fulfilled]: (state, { payload }) => {
      const { phones, pagination } = payload;
      const { limit, next_cursor, cursor } = pagination;
      state.pagination.cursor = next_cursor;
      state.pagination.limit = limit;
      state.phones = phones;
      if (!next_cursor && !cursor) state.isOnlyPage = true;
      else state.isOnlyPage = false;

      if (next_cursor) {
        if (!state?.isLastPage) {
          state.count = state.count + 1;
          state.cursors[state.page + 1] = next_cursor;
        }
      } else {
        state.isLastPage = true;
      }

      state.listPhonesStatus = STATUS.FULFILLED;
      if (!phones.length)
        state.listPhonesFeedbackMessage = MESSAGES.info(
          "No phones for selected tenant id"
        );
      else
        state.listPhonesFeedbackMessage = MESSAGES.success(
          "fetched list of phones"
        );
    },
    [listPhonesByTenantId.rejected]: (state, error) => {
      state.phones = [];
      state.listPhonesStatus = STATUS.REJECTED;
      state.listPhonesFeedbackMessage = error.message;
    },
    [addNewPhone.pending]: (state) => {
      state.addPhoneStatus = STATUS.PENDING;
    },
    [addNewPhone.fulfilled]: (state, { payload }) => {
      const { phone } = payload;
      state.phones.unshift(phone);
      state.addPhoneStatus = STATUS.FULFILLED;
      state.addPhoneFeedbackMessage = MESSAGES.success("added new phone");
    },
    [addNewPhone.rejected]: (state, { payload }) => {
      state.addPhoneStatus = STATUS.REJECTED;
      state.newMacAddress = "";
      state.addPhoneFeedbackMessage = payload;
    },
    [updatePhoneAlias.pending]: (state) => {
      state.updatePhoneStatus = STATUS.PENDING;
    },
    [updatePhoneAlias.fulfilled]: (state, { payload }) => {
      const { phone } = payload;
      state.phones = state.phones.map((ph) =>
        ph.id === phone.id ? phone : ph
      );
      state.updatePhoneStatus = STATUS.FULFILLED;
      state.updateFeedbackMessage = MESSAGES.success("updated Phone");
    },
    [updatePhoneAlias.rejected]: (state, { payload }) => {
      state.updatePhoneStatus = STATUS.REJECTED;
      state.updateFeedbackMessage = payload;
    },
    [updatePhoneEntityEnv.pending]: (state) => {
      state.updatePhoneStatus = STATUS.PENDING;
    },
    [updatePhoneEntityEnv.fulfilled]: (state, { payload }) => {
      const { phone } = payload;
      state.phones = state.phones.map((ph) =>
        ph.id === phone.id ? phone : ph
      );
      state.updatePhoneStatus = STATUS.FULFILLED;
      state.updateFeedbackMessage = MESSAGES.success("updated Phone Entity");
    },
    [updatePhoneEntityEnv.rejected]: (state, { payload }) => {
      state.updatePhoneStatus = STATUS.REJECTED;
      state.updateFeedbackMessage = payload;
    },
    [deletePhoneEntityEnv.pending]: (state) => {
      state.updatePhoneStatus = STATUS.PENDING;
    },
    [deletePhoneEntityEnv.fulfilled]: (state, { payload }) => {
      const { phone } = payload;
      state.phones = state.phones.map((ph) =>
        ph.id === phone.id ? phone : ph
      );
      state.updatePhoneStatus = STATUS.FULFILLED;
      state.updateFeedbackMessage = MESSAGES.success("deleted Phone Entity");
    },
    [deletePhoneEntityEnv.rejected]: (state, { payload }) => {
      state.updatePhoneStatus = STATUS.REJECTED;
      state.updateFeedbackMessage = payload;
    },
    [rebuildPhone.pending]: (state) => {
      state.rebuildPhoneStatus = STATUS.PENDING;
    },
    [rebuildPhone.fulfilled]: (state, { payload }) => {
      const { phone } = payload;
      state.phones = state.phones.map((ph) =>
        ph.id === phone.id ? phone : ph
      );
      state.rebuildPhoneStatus = STATUS.FULFILLED;
      state.rebuildFeedbackMessage = MESSAGES.success(
        "successfully rebuilt phone"
      );
    },
    [rebuildPhone.rejected]: (state, { payload }) => {
      state.rebuildPhoneStatus = STATUS.REJECTED;
      state.rebuildFeedbackMessage = payload;
    },
    [deletePhone.pending]: (state) => {
      state.deletePhoneStatus = STATUS.PENDING;
    },
    [deletePhone.fulfilled]: (state, { payload }) => {
      const { phoneId } = payload;
      state.phones = state.phones.filter((ph) => ph.id !== phoneId);
      state.deletePhoneStatus = STATUS.FULFILLED;
      state.deleteFeedbackMessage = MESSAGES.success(
        "successfully deleted phone"
      );
    },
    [deletePhone.rejected]: (state, { payload }) => {
      state.deletePhoneStatus = STATUS.REJECTED;
      state.deleteFeedbackMessage = payload;
    },
  },
});

export const getAllPhones = ({ phoneState }) => {
  return phoneState.phones;
};

export const getMacAddress = ({ phoneState }) => {
  return phoneState.newMacAddress;
};

export const getAlias = ({ phoneState }) => {
  return phoneState.newAlias;
};

export const getSearchTerm = ({ phoneState }) => {
  return phoneState.searchTerm;
};

export const getListPhonesFeedbackMessage = ({ phoneState }) =>
  phoneState?.listPhonesFeedbackMessage;
export const getListPhonesStatus = ({ phoneState }) =>
  phoneState?.listPhonesStatus;

export const getAddNewPhoneMessage = ({ phoneState }) =>
  phoneState?.addPhoneFeedbackMessage;
export const getAddPhoneStatus = ({ phoneState }) => phoneState?.addPhoneStatus;

export const getUpdatePhoneStatus = ({ phoneState }) =>
  phoneState?.updatePhoneStatus;
export const getUpdatePhoneMessage = ({ phoneState }) =>
  phoneState?.updateFeedbackMessage;

export const getRebuildFeedbackMessage = ({ phoneState }) =>
  phoneState?.rebuildFeedbackMessage;
export const getRebuildPhoneStatus = ({ phoneState }) =>
  phoneState?.rebuildPhoneStatus;

export const getDeleteFeedbackMessage = ({ phoneState }) =>
  phoneState?.deleteFeedbackMessage;
export const getDeletePhoneStatus = ({ phoneState }) =>
  phoneState?.deletePhoneStatus;

export const getPageNo = ({ phoneState }) => phoneState?.page;
export const getCount = ({ phoneState }) => phoneState?.count;
export const getPagination = ({ phoneState }) => phoneState?.pagination;
export const getOnlyPage = ({ phoneState }) => phoneState?.isOnlyPage;
export const getCursors = ({ phoneState }) => phoneState?.cursors;

export const { updateMacAddress, updateAlias, updateSearch, updatePage } =
  phoneSlice.actions;

export default phoneSlice.reducer;
