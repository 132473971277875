export const STATUS = Object.freeze({
  PENDING: "LOADING",
  FULFILLED: "SUCCESS",
  REJECTED: "ERROR",
});

export const FEEDBACK = Object.freeze({
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
  SUCCESS: "success",
});

export const oktaToken = "okta-token-storage";

export const MESSAGES = {
  success: (msg) => `${msg}`,
  info: (msg) => `INFO: ${msg}`,
};

export const TableHeaders = [
  {
    name: "alias",
    label: "Alias",
  },
  {
    name: "mac_address",
    label: "Mac Address",
  },
  {
    name: "entity_environments",
    label: "Environments",
  },
  {
    name: "created_at",
    label: "Created At",
  },
  {
    name: "updated_at",
    label: "Updated At",
  },
  {
    name: "built_at",
    label: "Built At",
  },
  {
    name: "assigned_user",
    label: "Assigned User",
  },
  {
    name: "is_v2",
    label: "V2",
  },
  {
    name: "actions",
    label: "Actions",
    roleDependent: true,
  },
];
