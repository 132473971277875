import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, PaginationItem, Stack } from "@mui/material";
import { getSelectedTenantId } from "../../features/tenants/tenantSlice";
import {
  updatePage,
  getPageNo,
  getCount,
  getPagination,
  listPhonesByTenantId,
  getCursors,
} from "../../features/phones/phoneSlice";

const BasicPagination = () => {
  const dispatch = useDispatch();
  const page = useSelector(getPageNo);
  const count = useSelector(getCount);
  const tenantId = useSelector(getSelectedTenantId);
  const pagination = useSelector(getPagination);
  const cursors = useSelector(getCursors);

  return (
    <Stack justifyContent="flex-end" alignItems="center" spacing={2}>
      <Pagination
        count={count}
        color="primary"
        page={page}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            onClick={async (e) => {
              const { page } = item;
              dispatch(updatePage(page));
              dispatch(
                listPhonesByTenantId({
                  tenantId,
                  pagination: { cursor: cursors[page], ...pagination },
                })
              );
            }}
          />
        )}
      />
    </Stack>
  );
};

export default BasicPagination;
