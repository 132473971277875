import { createSlice } from "@reduxjs/toolkit";

export const oktaSlice = createSlice({
  name: "oktaState",
  initialState: {
    userInfo: {},
    authState: {},
    roles: [],
    isAdmin: false,
    isManager: false,
  },
  reducers: {
    updateUserInfo: (state, { payload }) => {
      state.userInfo = payload;
    },
    updateAuthState: (state, { payload }) => {
      state.authState = payload;
    },
    updateUserRoles: (state, { payload }) => {
      state.roles = payload;
      if (payload?.includes("admin")) state.isAdmin = true;
      if (payload?.includes("manager")) state.isManager = true;
    },
  },
  extraReducers: {},
});

export const getAuthState = (state) => {
  return state.oktaState.authState;
};

export const getUserInfo = (state) => {
  return state.oktaState.userInfo;
};

export const getUserRole = (state) => {
  return state?.oktaState?.roles;
};

export const getIsAdmin = (state) => {
  return state?.oktaState?.isAdmin;
};

export const getIsManager = (state) => {
  return state?.oktaState?.isManager;
};

export const { updateUserInfo, updateAuthState, updateUserRoles } =
  oktaSlice.actions;

export default oktaSlice.reducer;
