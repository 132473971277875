import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { styled } from "@mui/material/styles";
import { useAddStyles } from "../../styles/phonismTheme";

import SelectEnvironment from "./SelectEnvironment";

import {
  getSelectedEnvIds,
  selectEnvs,
} from "../../features/environments/environmentSlice";
import {
  getAlias,
  updateAlias,
  updatePhoneAlias,
  updatePhoneEntityEnv,
  deletePhoneEntityEnv,
  getUpdatePhoneStatus,
  getRebuildPhoneStatus,
  getDeletePhoneStatus,
  getUpdatePhoneMessage,
} from "../../features/phones/phoneSlice";

import { STATUS, FEEDBACK } from "../../utils/constants";
import { getEnvIds } from "../../utils/helpers";
import Feedback from "../common/Feedback";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useAddStyles();

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
          classes={{ root: classes.icon }}
        >
          <CloseIcon classes={{ root: classes.icon }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Edit({
  phone,
  processingPhoneId,
  handleProcessingPhoneId,
}) {
  const classes = useAddStyles();
  const dispatch = useDispatch();
  const entityEnvs = useSelector(getSelectedEnvIds);

  const phoneAlias = useSelector(getAlias);
  const feedbackMessage = useSelector(getUpdatePhoneMessage);
  const updatePhoneStatus = useSelector(getUpdatePhoneStatus);
  const rebuildPhoneStatus = useSelector(getRebuildPhoneStatus);
  const deletePhoneStatus = useSelector(getDeletePhoneStatus);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    dispatch(updateAlias(phone?.alias));
    dispatch(selectEnvs(phone?.entity_environments?.map((env) => env.alias)));
  };

  const handleUpdatePhone = () => {
    handleProcessingPhoneId(phone?.id);

    if (phone.alias !== phoneAlias)
      dispatch(
        updatePhoneAlias({ phoneId: phone?.id, phoneAlias: phoneAlias.trim() })
      );

    const existingEntityEnvs = phone?.entity_environments?.map((env) => env.id);

    if (entityEnvs.length === existingEntityEnvs.length) {
      const existingEnvsSorted = (existingEntityEnvs || []).slice().sort();
      const entityEnvsSorted = (entityEnvs || []).slice().sort();

      if (entityEnvsSorted.every((env, i) => env === existingEnvsSorted[i]))
        return setOpen(false);

      existingEntityEnvs.forEach((envId) => {
        dispatch(
          deletePhoneEntityEnv({ phoneId: phone.id, entityEnvId: envId })
        );
      });

      entityEnvs.forEach((envId) => {
        dispatch(
          updatePhoneEntityEnv({ phoneId: phone.id, entityEnvId: envId })
        );
      });
    } else if (entityEnvs.length < existingEntityEnvs.length) {
      const deletedEnvs = getEnvIds(existingEntityEnvs, entityEnvs);
      deletedEnvs.forEach((envId) =>
        dispatch(
          deletePhoneEntityEnv({ phoneId: phone.id, entityEnvId: envId })
        )
      );
    } else {
      const addedEnvs = getEnvIds(entityEnvs, existingEntityEnvs);
      addedEnvs.forEach((envId) =>
        dispatch(
          updatePhoneEntityEnv({ phoneId: phone.id, entityEnvId: envId })
        )
      );
    }

    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnAliasChange = (event) => {
    dispatch(updateAlias(event.target.value));
  };

  return (
    <>
      {updatePhoneStatus === STATUS.REJECTED && (
        <Feedback
          severity={FEEDBACK.ERROR}
          message={feedbackMessage}
          autoHideDuration={6000}
        />
      )}
      {updatePhoneStatus === STATUS.FULFILLED && (
        <Feedback
          severity={FEEDBACK.SUCCESS}
          message={feedbackMessage}
          autoHideDuration={1000}
        />
      )}
      <Button
        disabled={
          phone?.id === processingPhoneId &&
          (rebuildPhoneStatus === STATUS.PENDING ||
            updatePhoneStatus === STATUS.PENDING ||
            deletePhoneStatus === STATUS.PENDING)
        }
        variant="text"
        onClick={handleClickOpen}
        classes={{ root: classes.button }}
      >
        {updatePhoneStatus === STATUS.PENDING && phone.id === processingPhoneId
          ? "Updating..."
          : "Edit"}
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="update-title"
        open={open}
      >
        <BootstrapDialogTitle id="update-title" onClose={handleClose}>
          Update Phone
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <TextField
            disabled
            value={phone?.mac_address}
            fullWidth
            inputProps={{
              maxLength: 12,
            }}
            label="Mac Address"
            id="mac_address"
            classes={{ root: classes.root }}
            InputProps={{
              classes: {
                root: classes.outlinedInput,
                disabled: classes.disabled,
              },
            }}
          />
          <TextField
            onChange={handleOnAliasChange}
            value={phoneAlias}
            fullWidth
            label="Alias"
            id="alias"
            classes={{ root: classes.root }}
            InputProps={{
              classes: {
                root: classes.outlinedInput,
              },
            }}
          />

          <SelectEnvironment />
        </DialogContent>
        <DialogActions>
          <Button
            classes={{ root: classes.button }}
            autoFocus
            onClick={handleUpdatePhone}
          >
            Update
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
