import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { TableHeaders } from "../../utils/constants";
import Rebuild from "./Rebuild";
import Delete from "./Delete";
import Edit from "./Edit";
import { getSelectedTenantId } from "../../features/tenants/tenantSlice";
import { getIsManager, getIsAdmin } from "../../features/okta/oktaSlice";
import {
  listPhonesByTenantId,
  getListPhonesFeedbackMessage,
  getListPhonesStatus,
  getAllPhones,
  getPageNo,
  getPagination,
  getOnlyPage,
} from "../../features/phones/phoneSlice";
import { STATUS, FEEDBACK } from "../../utils/constants";
import Feedback from "../common/Feedback";
import Loading from "../common/Loading";
import BasicPagination from "./Pagination";
import { useTableStyles } from "../../styles/phonismTheme";

const MainTable = () => {
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const tenantId = useSelector(getSelectedTenantId);
  const isAdmin = useSelector(getIsAdmin);
  const isManager = useSelector(getIsManager);
  const feedbackMessage = useSelector(getListPhonesFeedbackMessage);
  const listPhoneStatus = useSelector(getListPhonesStatus);
  const phones = useSelector(getAllPhones);
  const pagination = useSelector(getPagination);
  const pageNo = useSelector(getPageNo);
  const isOnlyPage = useSelector(getOnlyPage);

  const [processingPhoneId, setProcessingPhoneId] = useState("");
  const handleProcessingPhoneId = (phoneId) => {
    setProcessingPhoneId(phoneId);
  };

  useEffect(() => {
    if (tenantId) {
      dispatch(listPhonesByTenantId({ tenantId, pagination }));
    }
  }, [tenantId, dispatch]);

  return (
    <>
      {listPhoneStatus === STATUS.PENDING ? (
        <Loading />
      ) : (
        <TableContainer component={Paper} classes={{ root: classes.tableRoot }}>
          {listPhoneStatus === STATUS.REJECTED && (
            <Feedback
              severity={FEEDBACK.ERROR}
              message={feedbackMessage}
              autoHideDuration={6000}
            />
          )}
          {listPhoneStatus === STATUS.FULFILLED && (
            <Feedback
              severity={FEEDBACK.SUCCESS}
              message={feedbackMessage}
              autoHideDuration={1000}
            />
          )}
          <Table stickyHeader sx={{ minWidth: 450 }} aria-label="simple table">
            <TableHead>
              {(phones || []).length ? (
                <TableRow>
                  {TableHeaders.map((TB, index) => {
                    let showHeading = true;
                    TB.roleDependent
                      ? isAdmin || isManager
                        ? (showHeading = true)
                        : (showHeading = false)
                      : (showHeading = true);
                    if (showHeading) {
                      return (
                        <TableCell align="left" key={`${TB.name}_${index}`}>
                          {TB.label}
                        </TableCell>
                      );
                    } else return null;
                  })}
                </TableRow>
              ) : null}
            </TableHead>
            <TableBody>
              {phones.map((row) => (
                <TableRow
                  key={row?.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row?.alias}
                  </TableCell>
                  <TableCell align="left">{row.mac_address}</TableCell>
                  <TableCell align="left">
                    {row?.entity_environments?.map((ev) => {
                      return <div key={ev.id}>{ev.alias}</div>;
                    })}
                  </TableCell>
                  <TableCell align="left">{row?.created_at}</TableCell>
                  <TableCell align="left">{row?.updated_at}</TableCell>
                  <TableCell align="left">{row?.built_at}</TableCell>
                  <TableCell align="left">{row?.assigned_user}</TableCell>
                  <TableCell align="left">
                    {row?.is_v2 ? "Yes" : "No"}
                  </TableCell>
                  <TableCell align="left">
                    {isManager || isAdmin ? (
                      <Edit
                        phone={row}
                        processingPhoneId={processingPhoneId}
                        handleProcessingPhoneId={handleProcessingPhoneId}
                      />
                    ) : null}
                    {isManager || isAdmin ? (
                      <Rebuild
                        phoneId={row.id}
                        processingPhoneId={processingPhoneId}
                        handleProcessingPhoneId={handleProcessingPhoneId}
                      />
                    ) : null}
                    {isAdmin ? (
                      <Delete
                        phoneId={row.id}
                        processingPhoneId={processingPhoneId}
                        handleProcessingPhoneId={handleProcessingPhoneId}
                      />
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {pageNo >= 1 && !isOnlyPage ? <BasicPagination /> : null}
    </>
  );
};

export default MainTable;
