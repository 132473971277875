export const getIdByAlias = (arr, alias) => {
  if (!Array.isArray(arr)) return null;

  const { id } = arr.find((element) => element.alias === alias) || {};
  return id;
};

export const getEnvIds = (first, second) => {
  if (!Array.isArray(first) || !Array.isArray(second)) return null;

  let envIds = [];
  first.forEach((env) => !second.includes(env) && envIds.push(env));
  return envIds;
};

export const getAvatarText = (firstName, lastName) => {
  if (!firstName || !lastName) return null;
  return firstName[0] + lastName[0];
};
