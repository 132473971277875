import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

import { ThemeProvider } from "@mui/material/styles";
import { PhonismTheme } from "../../styles/phonismTheme";

const Loading = () => {
  return (
    <ThemeProvider theme={PhonismTheme}>
      <CircularProgress size={40} />
    </ThemeProvider>
  );
};

export default Loading;
