import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './Router';
import './index.css';

const App = () => (
  <Router>
    <AppRouter />
  </Router>
);

export default App;