import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Toolbar, AppBar, Typography, Box } from "@mui/material";
import { STATUS, FEEDBACK } from "../../utils/constants";
import Feedback from "../common/Feedback";
import SelectTenant from "./SelectTenant";
import User from "./User";
import {
  listAllTenants,
  getStatus,
  getFeedbackMessage,
  getDefaultTenant,
} from "../../features/tenants/tenantSlice";

const Tenants = () => {
  const dispatch = useDispatch();
  const status = useSelector(getStatus);
  const feedbackMessage = useSelector(getFeedbackMessage);
  const defaultTenant = useSelector(getDefaultTenant);

  useEffect(() => {
    dispatch(listAllTenants());
  }, [dispatch]);

  const userInfo = {
    name: "Manasa Kommareddy",
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      {status === STATUS.REJECTED && (
        <Feedback
          severity={FEEDBACK.ERROR}
          message={feedbackMessage}
          autoHideDuration={6000}
        />
      )}
      {status === STATUS.FULFILLED && (
        <Feedback
          severity={FEEDBACK.SUCCESS}
          message={feedbackMessage}
          autoHideDuration={1000}
        />
      )}

      <AppBar position="sticky">
        <Toolbar>
          {status === STATUS.PENDING ? (
            "Loading ..."
          ) : (
            <>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                Tenant : {"   "}
              </Typography>
              <SelectTenant defaultTenant={defaultTenant} />
            </>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{ display: { xs: "none", md: "flex", alignItems: "center" } }}
          >
            <User userInfo={userInfo} />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Tenants;
