import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MenuItem, FormControl, Select, InputLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ThemeProvider } from "@mui/material/styles";
import { PhonismTheme } from "../../styles/phonismTheme";
import {
  allEnvs,
  selectEnvs,
  getSelectedEnvs,
} from "../../features/environments/environmentSlice";
import { updateAlias } from "../../features/phones/phoneSlice";

const useStyles = makeStyles((theme) => ({
  formControlRoot: {
    margin: theme.spacing(0.5),
    width: `calc(100% - ${theme.spacing(2)})`,
  },
  select: {
    color: "#000",
  },
}));

export default function SelectTenant({ isAddBox }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const entityEnvs = useSelector(allEnvs);
  const selectedEnvs = useSelector(getSelectedEnvs);

  useEffect(() => {
    if (isAddBox) {
      dispatch(selectEnvs([]));
      dispatch(updateAlias(""));
    }
  }, []);

  const handleChange = (event) => {
    dispatch(selectEnvs(event.target.value));
  };

  return (
    <ThemeProvider theme={PhonismTheme}>
      <FormControl classes={{ root: classes.formControlRoot }}>
        <InputLabel id="select-env-id">Environment</InputLabel>
        <Select
          classes={{ select: classes.select }}
          id="select-env-id"
          value={selectedEnvs}
          onChange={handleChange}
          multiple
          fullWidth
          label="Environment"
        >
          {(entityEnvs || []).map((env, index) => {
            return (
              <MenuItem
                key={`${index}_${env.id}`}
                value={env.alias || env.environment || env.id}
              >
                {env.alias || env.environment || env.id}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
}
