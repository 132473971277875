import { createSlice } from "@reduxjs/toolkit";

export const envSlice = createSlice({
  name: "environmentState",
  initialState: {
    entityEnvironments: [],
    selectedEnvs: [],
  },
  reducers: {
    updateEntityEnvs: (state, { payload }) => {
      state.entityEnvironments = payload;
    },
    selectEnvs: (state, { payload }) => {
      state.selectedEnvs = payload;
    },
  },
  extraReducers: {},
});

export const allEnvs = (state) => {
  return state.environmentState.entityEnvironments;
};

export const getSelectedEnvs = (state) => {
  return state.environmentState.selectedEnvs;
};

export const getSelectedEnvIds = (state) => {
  const { environmentState } = state;
  const { selectedEnvs, entityEnvironments } = environmentState;
  let entityEnvIds = [];
  (entityEnvironments || []).forEach((env) => {
    if ((selectedEnvs || []).includes(env?.alias)) entityEnvIds.push(env.id);
  });

  return entityEnvIds;
};

export const { updateEntityEnvs, selectEnvs } = envSlice.actions;

export default envSlice.reducer;
