import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import {
  Typography,
  Box,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  IconButton,
  Tooltip,
} from "@mui/material";
import Logout from "@mui/icons-material/Logout";
import { getUserInfo } from "../../features/okta/oktaSlice";
import { useSelector } from "react-redux";
import { userStyles } from "../../styles/phonismTheme";
import { getAvatarText } from "../../utils/helpers";

const User = () => {
  const userInfo = useSelector(getUserInfo);
  const { oktaAuth } = useOktaAuth();

  const handleLogout = () => {
    oktaAuth.signOut("/");
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Typography
        variant="h6"
        noWrap
        component="div"
        sx={{ display: { xs: "none", sm: "block" } }}
      >
        {userInfo.name || userInfo.preferred_username || userInfo.email}
      </Typography>

      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Manage Account">
          <IconButton
            onClick={handleClick}
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={userStyles.avatar}>
              {getAvatarText(userInfo?.given_name, userInfo?.family_name) ||
                (userInfo?.name && userInfo?.name[0])}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: userStyles.menu,
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default User;
