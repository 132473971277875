import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllTenants } from "../../services";
import { STATUS, MESSAGES } from "../../utils/constants";

export const listAllTenants = createAsyncThunk(
  "tenants/listAllTenants",
  async () => {
    const { data } = await getAllTenants();
    return data;
  }
);

export const tenantsSlice = createSlice({
  name: "tenantState",
  initialState: {
    tenants: [],
    status: null,
    selectedTenant: {},
    selectedTenantId: "",
    feedbackMessage: "",
  },
  reducers: {
    saveSelectedTenantId: (state, { payload }) => {
      state.selectedTenantId = payload;
    },
  },
  extraReducers: {
    [listAllTenants.pending]: (state) => {
      state.status = STATUS.PENDING;
    },
    [listAllTenants.fulfilled]: (state, { payload }) => {
      const { tenants } = payload;
      state.tenants = [...tenants];
      state.status = STATUS.FULFILLED;
      state.feedbackMessage = MESSAGES.success("tenants");
    },
    [listAllTenants.rejected]: (state, { error }) => {
      state.status = STATUS.REJECTED;
      state.feedbackMessage = error.message;
    },
  },
});

export const getStatus = ({ tenantState }) => tenantState?.status;
export const getFeedbackMessage = ({ tenantState }) =>
  tenantState?.feedbackMessage;
export const allTenants = ({ tenantState }) => tenantState?.tenants;

export const getDefaultTenant = ({ tenantState }) => {
  const { tenants = [] } = tenantState;
  if (tenants.length && tenants.length >= 1) {
    return tenants[0]; // First tenant is the default tenant
  } else return {};
};

export const getSelectedTenantId = ({ tenantState }) => {
  return tenantState.selectedTenantId;
};

export const { saveSelectedTenantId } = tenantsSlice.actions;

export default tenantsSlice.reducer;
