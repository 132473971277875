import React from "react";
import { useSelector } from "react-redux";

import Add from "./Add";
import MainTable from "./Table";

import { getStatus } from "../../features/tenants/tenantSlice";
import { STATUS } from "../../utils/constants";

const Body = () => {
  const status = useSelector(getStatus);
  return (
    <>
      {status === STATUS.PENDING ? null : (
        <>
          <Add />
          <MainTable />
        </>
      )}
    </>
  );
};

export default Body;
