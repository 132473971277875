import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MenuItem, FormControl, Select } from "@mui/material";
import { STATUS } from "../../utils/constants";
import { getIdByAlias } from "../../utils/helpers";
import { ThemeProvider } from "@mui/material/styles";
import { PhonismTheme, useHeaderStyles } from "../../styles/phonismTheme";
import {
  allTenants,
  saveSelectedTenantId,
} from "../../features/tenants/tenantSlice";
import { updateEntityEnvs } from "../../features/environments/environmentSlice";
import { getListPhonesStatus } from "../../features/phones/phoneSlice";

export default function SelectTenant({ defaultTenant }) {
  const tenants = useSelector(allTenants);
  const phoneStatus = useSelector(getListPhonesStatus);
  const dispatch = useDispatch();
  const classes = useHeaderStyles();

  useEffect(() => {
    if (defaultTenant) {
      selectTenant(defaultTenant?.alias);
      dispatch(updateEntityEnvs(defaultTenant?.entity_environments));
      const id = getIdByAlias(tenants, defaultTenant?.alias);
      if (id) dispatch(saveSelectedTenantId(id));
    }
  }, [defaultTenant, tenants, dispatch]);

  const [selectedTenant, selectTenant] = useState(defaultTenant);

  const handleChange = (event) => {
    selectTenant(event.target.value);
    const id = getIdByAlias(tenants, event.target.value);
    if (id) dispatch(saveSelectedTenantId(id));
  };

  return (
    <ThemeProvider theme={PhonismTheme}>
      <FormControl sx={{ m: 1, minWidth: 80, color: "primary.main" }}>
        <Select
          id="select-tenant-id"
          value={selectedTenant}
          onChange={handleChange}
          autoWidth
          disabled={phoneStatus === STATUS.PENDING}
          className={classes.select}
          classes={{ icon: classes.icon }}
        >
          {tenants.map((tenant, index) => {
            return (
              <MenuItem
                key={`${index}_${tenant.id}`}
                value={tenant.alias || tenant.id}
              >
                {tenant.alias || tenant.id}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
}
