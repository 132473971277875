import React from "react";
import { Grid } from "@mui/material";
import { PhonismTheme } from "./styles/phonismTheme";
import { ThemeProvider } from "@mui/material/styles";
import Header from "./components/header";
import Body from "./components/body";

import "./App.css";

const Home = () => {
  return (
    <ThemeProvider theme={PhonismTheme}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item>
          <Header />
        </Grid>
        <Grid item>
          <Body />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Home;
