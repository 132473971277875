import React from "react";
import { Button } from "@mui/material";
import { useAddStyles } from "../../styles/phonismTheme";
import { useDispatch, useSelector } from "react-redux";
import {
  rebuildPhone,
  getRebuildFeedbackMessage,
  getRebuildPhoneStatus,
  getDeletePhoneStatus,
  getUpdatePhoneStatus,
} from "../../features/phones/phoneSlice";
import { STATUS, FEEDBACK } from "../../utils/constants";
import Feedback from "../common/Feedback";

const Rebuild = ({ phoneId, handleProcessingPhoneId, processingPhoneId }) => {
  const classes = useAddStyles();
  const dispatch = useDispatch();

  const feedbackMessage = useSelector(getRebuildFeedbackMessage);
  const rebuildPhoneStatus = useSelector(getRebuildPhoneStatus);
  const updatePhoneStatus = useSelector(getUpdatePhoneStatus);
  const deletePhoneStatus = useSelector(getDeletePhoneStatus);

  const handleEditButton = async (e) => {
    handleProcessingPhoneId(phoneId);
    dispatch(rebuildPhone(phoneId));
  };

  return (
    <>
      {rebuildPhoneStatus === STATUS.REJECTED && (
        <Feedback
          severity={FEEDBACK.ERROR}
          message={feedbackMessage}
          autoHideDuration={6000}
        />
      )}
      {rebuildPhoneStatus === STATUS.FULFILLED && (
        <Feedback
          severity={FEEDBACK.SUCCESS}
          message={feedbackMessage}
          autoHideDuration={1000}
        />
      )}
      <Button
        disabled={
          phoneId === processingPhoneId &&
          (rebuildPhoneStatus === STATUS.PENDING ||
            updatePhoneStatus === STATUS.PENDING ||
            deletePhoneStatus === STATUS.PENDING)
        }
        classes={{ root: classes.button }}
        variant="text"
        onClick={handleEditButton}
      >
        {rebuildPhoneStatus === STATUS.PENDING && phoneId === processingPhoneId
          ? "Rebuilding...."
          : "Rebuild"}
      </Button>
    </>
  );
};

export default Rebuild;
