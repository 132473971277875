import { configureStore } from "@reduxjs/toolkit";
import tenantsReducer from "../features/tenants/tenantSlice";
import envReducer from "../features/environments/environmentSlice";
import phonesReducer from "../features/phones/phoneSlice";
import oktaReducer from "../features/okta/oktaSlice";

export const store = configureStore({
  reducer: {
    oktaState: oktaReducer,
    tenantState: tenantsReducer,
    phoneState: phonesReducer,
    environmentState: envReducer,
  },
});
