import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputBase, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useSearchStyles } from "../../styles/phonismTheme";
import {
  updateSearch,
  getSearchTerm,
  listPhonesByTenantId,
} from "../../features/phones/phoneSlice";
import { getSelectedTenantId } from "../../features/tenants/tenantSlice";

const Search = () => {
  const classes = useSearchStyles();
  const dispatch = useDispatch();
  const search = useSelector(getSearchTerm);
  const tenantId = useSelector(getSelectedTenantId);

  const handleSearch = (event) => {
    dispatch(updateSearch(event.target.value));
  };

  const handleSearchClick = (event) => {
    dispatch(listPhonesByTenantId({ tenantId, search: search?.trim() }));
  };

  const handleOnKeyDown = (event) => {
    if (event.keyCode === 13) {
      dispatch(listPhonesByTenantId({ tenantId, search: search?.trim() }));
    }
  };

  return (
    <div className={classes.search}>
      <InputBase
        value={search}
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search..."
        inputProps={{ "aria-label": "search phones" }}
        onChange={handleSearch}
        onKeyDown={handleOnKeyDown}
      />
      <IconButton
        type="submit"
        sx={{ p: "10px" }}
        aria-label="search"
        classes={{ root: classes.icon }}
        onClick={handleSearchClick}
      >
        <SearchIcon classes={{ root: classes.icon }} />
      </IconButton>
    </div>
  );
};

export default Search;
