import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useAddStyles } from "../../styles/phonismTheme";
import SelectEnvironment from "./SelectEnvironment";
import { getSelectedEnvIds } from "../../features/environments/environmentSlice";
import {
  getMacAddress,
  getAlias,
  updateMacAddress,
  updateAlias,
  addNewPhone,
  getAddPhoneStatus,
  getAddNewPhoneMessage,
} from "../../features/phones/phoneSlice";
import { getSelectedTenantId } from "../../features/tenants/tenantSlice";
import { getIsAdmin } from "../../features/okta/oktaSlice";
import { STATUS, FEEDBACK } from "../../utils/constants";
import Feedback from "../common/Feedback";
import Search from "./Search";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useAddStyles();

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
          classes={{ root: classes.icon }}
        >
          <CloseIcon classes={{ root: classes.icon }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function Add() {
  const classes = useAddStyles();
  const dispatch = useDispatch();
  const entityEnvs = useSelector(getSelectedEnvIds);
  const tenandId = useSelector(getSelectedTenantId);
  const macAddress = useSelector(getMacAddress);
  const phoneAlias = useSelector(getAlias);
  const feedbackMessage = useSelector(getAddNewPhoneMessage);
  const addPhoneStatus = useSelector(getAddPhoneStatus);
  const isAdmin = useSelector(getIsAdmin);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAddNewPhone = () => {
    dispatch(addNewPhone({ macAddress, tenandId, entityEnvs, phoneAlias }));
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnMacAddressChange = (event) => {
    dispatch(updateMacAddress(event?.target?.value?.trim()));
  };

  const handleOnAliasChange = (event) => {
    dispatch(updateAlias(event.target.value));
  };

  return (
    <div className={classes.topContainer}>
      <div className={classes.add}>
        {addPhoneStatus === STATUS.REJECTED && (
          <Feedback
            severity={FEEDBACK.ERROR}
            message={feedbackMessage}
            autoHideDuration={6000}
          />
        )}
        {addPhoneStatus === STATUS.FULFILLED && (
          <Feedback
            severity={FEEDBACK.SUCCESS}
            message={feedbackMessage}
            autoHideDuration={1000}
          />
        )}
        {isAdmin ? (
          <Button
            disabled={addPhoneStatus === STATUS.PENDING}
            variant="text"
            onClick={handleClickOpen}
            classes={{ root: classes.button }}
          >
            {addPhoneStatus === STATUS.PENDING ? "Adding..." : "Add"}
          </Button>
        ) : null}
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Add new Phone
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <TextField
              onChange={handleOnMacAddressChange}
              fullWidth
              inputProps={{
                maxLength: 12,
              }}
              label="Mac Address"
              id="mac_address"
              classes={{ root: classes.root }}
              InputProps={{
                classes: {
                  root: classes.outlinedInput,
                },
              }}
            />
            <TextField
              onChange={handleOnAliasChange}
              fullWidth
              label="Alias"
              id="alias"
              classes={{ root: classes.root }}
              InputProps={{
                classes: {
                  root: classes.outlinedInput,
                },
              }}
            />
            <SelectEnvironment isAddBox={true} />
          </DialogContent>
          <DialogActions>
            <Button
              classes={{ root: classes.button }}
              autoFocus
              disabled={!macAddress}
              onClick={handleAddNewPhone}
            >
              Add
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
      <Search />
    </div>
  );
}
