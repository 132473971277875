import React from "react";
import { Button } from "@mui/material";
import { useAddStyles } from "../../styles/phonismTheme";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePhone,
  getDeleteFeedbackMessage,
  getRebuildPhoneStatus,
  getDeletePhoneStatus,
  getUpdatePhoneStatus,
} from "../../features/phones/phoneSlice";
import { STATUS, FEEDBACK } from "../../utils/constants";
import Feedback from "../common/Feedback";

const Delete = ({ phoneId, handleProcessingPhoneId, processingPhoneId }) => {
  const classes = useAddStyles();
  const dispatch = useDispatch();

  const feedbackMessage = useSelector(getDeleteFeedbackMessage);
  const rebuildPhoneStatus = useSelector(getRebuildPhoneStatus);
  const updatePhoneStatus = useSelector(getUpdatePhoneStatus);
  const deletePhoneStatus = useSelector(getDeletePhoneStatus);

  const handleDeleteButton = async (e) => {
    handleProcessingPhoneId(phoneId);
    dispatch(deletePhone(phoneId));
  };

  return (
    <>
      {deletePhoneStatus === STATUS.REJECTED && (
        <Feedback
          severity={FEEDBACK.ERROR}
          message={feedbackMessage}
          autoHideDuration={6000}
        />
      )}
      {deletePhoneStatus === STATUS.FULFILLED && (
        <Feedback
          severity={FEEDBACK.SUCCESS}
          message={feedbackMessage}
          autoHideDuration={1000}
        />
      )}
      <Button
        disabled={
          processingPhoneId === phoneId &&
          (rebuildPhoneStatus === STATUS.PENDING ||
            updatePhoneStatus === STATUS.PENDING ||
            deletePhoneStatus === STATUS.PENDING)
        }
        classes={{ root: classes.button }}
        variant="text"
        onClick={handleDeleteButton}
      >
        {deletePhoneStatus === STATUS.PENDING && processingPhoneId === phoneId
          ? "Deleting...."
          : "Delete"}
      </Button>
    </>
  );
};

export default Delete;
