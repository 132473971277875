const config = {
  oidc: {
    clientId: window.OKTA_CLIENT_ID,
    issuer: window.OKTA_ISSUER,
    redirectUri: "/login/callback",
    scopes: ["openid", "profile", "email"],
    pkce: true,
    disableHttpsCheck: window.OKTA_TESTING_DISABLE_HTTPS_CHECK,
  },
};

export default config;
